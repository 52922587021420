

import {Component, Vue} from "vue-property-decorator";
import {DBService} from "@/components/Database/DBService";
import HomeNews from "@/components/HomeNews.vue";

@Component({
  components: {HomeNews}
})
export default class Home extends Vue {
  slides: any[] = [];
  language: string | null = localStorage.getItem("lang");

  constructor() {
    super();
    this.getSlideShowFromDB()
    this.language = localStorage.getItem("lang")
  }

  async getSlideShowFromDB() {
    const slides = (await DBService.getInstance().getSlideShow());
    if (slides) {
      this.slides = slides;
    }
  }

  created() {
    if (localStorage.getItem("lang") === null)
      localStorage.setItem("lang", 'de')
  }

  // Funktion, um zu überprüfen, ob das aktuelle Datum im Bereich liegt
  isActiveBetween(startDate: Date, endDate: Date): boolean {
    const now = new Date("2024-12-06T00:00:00");
    return now >= startDate && now <= endDate;
  }

// Start- und Enddatum definieren
  startDate = new Date("2024-12-06T00:00:00");
  endDate = new Date("2025-01-07T23:59:59");

// Variable setzen
  isChristmas = this.isActiveBetween(this.startDate, this.endDate);

}
