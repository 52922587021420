export default {
    categories: {
        home: "Home",
        products: "Products",
        company: "Company",
        career: "Career",
        news: "News",
        contact: "Contact"
    },

    home: {
        welcome: "Burri Werkzeugmaschinen GmbH Co. KG",
        eco: "Cost Effectiveness and Innovation made in Germany",

        welcome_text: "We are the leading manufacturer of wheel profiling machines for form and worm wheels and the " +
            "only company worldwide which offers professional retrofitting of Reishauer gear grinding machines. " +
            "Simultaneously, we also have designed our own range of gear, worm and thread grinding machines on our " +
            "modular and patented platform, which you can see on our portfolio. As a system provider, we provide you " +
            "with a complete range of accessories such as automation, clamping devices, filtration, and grinding tools " +
            "with expert advice and a targeted design for your area of application.",

        products: "Our Products",

        villingendorf: "From Villingendorf into the whole world",

        villingendorf_text: "All our machines are developed, produced, and exported worldwide here in Villingendorf " +
            "near Rottweil (Baden-Württemberg), the heart of the German machine tool industry. Come over! It is worth " +
            "visiting Rottweil, the oldest city in Baden-Württemberg, home of the world-famous Rottweiler dogs and the " +
            "Thyssen-Krupp elevator test tower with Germany's highest viewing platform.",

        christmas_text: "Dear business partners, <br><br>" +
            "the Christmas season gives us an ideal opportunity\n" +
            "to reflect and say thank you for a good year.\n" +
            "We have realised a lot together. It‘s great that we can\n" +
            "count on each other. Trust is a great gift.\n <br>" +
            "We wish you a blessed holiday season with your\n" +
            "loved ones and families and much health,\n" +
            "happiness and success in the new year.",

        opening_hours: "Please note our opening hours between Christmas and New Year: <br>" +
            "We will be closed from 23. December 2024 – 06. January 2025 and will be reliably available for you again from 07. January 2025."
    },

    products: {
        geargrinding: "Gear Grinding Machines",
        profiling: "Profiling Machines",
        clamping: "Clamping Systems",
        more: "More",

        technical_data: "Technical Specifications",
        request: "Request now",
        advantages: "Your benefits"
    },

    clamping: {
        text: " We recognized early that a high-precision clamping system is essential for high grinding quality and " +
            "short cycle times. This is why we are developing and producing our own clamping devices for all our " +
            "machines in Villingendorf. All the clamping solutions are based on our quick lock clamping system. " +
            "This system allows a rigid connection to the machine, setup time within seconds as well as high " +
            "repeatability. Our portfolio offers different modular clamping systems for shafts, bore parts, rings, " +
            "and other configurations. Because of the modular construction, delivery times can be kept short. " +
            "If our standard system doesn't fit your requirement, we will develop customer-specific solutions for you.",
    },

    current: {
        current: "Current",
        fairs: "Exhibitions",
        innovation: "Innovation"
    },

    company: {
        burri: "Burri Werkzeugmaschinen GmbH & Co.KG",
        text: "Dear business partners, <br><br>" +
            "after gaining lots of experience and know how in the gear grinding field while working as a service " +
            "engineer for more than a decade at Reishauer I founded Burri Werkzeugmaschinen GmbH & Co. KG in 2002.<br>" +
            "Even since 1999 (3 years before founding) I have replaced profiling change wheels and manual adjustment " +
            "of the A-axis on Reishauer machine types RZ301 and RZ362 with NC-axes. Thus, the worldwide first change " +
            "wheel free and CNC-controlled Reishauer generating grinding machine was a by Burri retrofitted RZ362A.<br><br>" +
            "Decide for a Burri machine from an owner-managed medium-sized company with highliy motivated and competent employees.<br>" +
            "I personally guarantee the best quality along with high efficiency.<br><br>" +
            "Dieter Burri <br> Owner and General Manager",
        history: "History",
        sustainability: "Sustainability",
        sustainability_text: "We don't just talk about it, we also implement it and we have always done so. " +
            "All of our gear grinding machines are unbeatable in terms of their power consumption and are by far the " +
            "most energy-efficient gear grinding machines currently available on the market. <br><br>" +
            "How do we make it? Our really maintenance friendly hightech machine control with Acopos multi drive system " +
            "and power recovery from B&R (ABB) which is exactly adapted to each machine type is one of the main reasons. " +
            "Another reason is the compact hydraulic unit (only 3.5 liter volume) which merely will be controlled when " +
            "pressure is required.",
        membership: "Association Membership",
        membership_text: "We are member of",
        team: "Our Team"
    },

    contact: {
        germany: "Burri Germany",
        international: "Burri International",
        phone: "Phone",
        fax: "Fax",
        mail: "Mail"
    },

    request: {
        name: "First- and Lastname",
        company: "Company",
        mail: "Mail",
        subject: "Subject",
        message: "Message",
        send: "Send",
        cancel: "Cancel"
    }
};
